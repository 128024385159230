"use client";
import React, { ReactNode } from "react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import {
  CaretDownIcon,
  CheckIcon,
  GlobeIcon,
  HamburgerMenuIcon,
} from "@radix-ui/react-icons";
import styles from "./Header.module.scss";
import { Box, Flex, Link as RadixLink, Separator } from "@radix-ui/themes";
import { Link, usePathname, useRouter } from "@/navigation";
import { useLocale, useTranslations } from "next-intl";
import Image from "next/image";
import logoText from "/public/logo_text.svg";
import { useUserSession } from "@/contexts/UserSession";
import { MyUser } from "@/api/entities/myUser";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import { useParams, useSearchParams } from "next/navigation";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { DesktopView } from "@/design-system/responsive-helpers/DesktopView";
import { NonDesktopView } from "@/design-system/responsive-helpers/NonDesktopView";
import ProfileAvatar from "@/design-system/components/Avatar/ProfileAvatar";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "@/design-system/components/Sheet/Sheet";

type ResponsiveState = "mobile" | "desktop" | "both";
type AuthenticatedState = "unauthenticated" | "authenticated" | "both";

type NavigationItem = {
  node: React.ReactNode;
  responsiveState?: ResponsiveState;
  authenticatedState?: AuthenticatedState;
};

function Header({
  hideSessionInfo,
  hideLocalePicker,
  navigationItems,
  showTitleSubtext,
  breadcrumbs,
}: {
  hideSessionInfo?: boolean;
  hideLocalePicker?: boolean;
  navigationItems: NavigationItem[];
  showTitleSubtext?: boolean;
  breadcrumbs?: ReactNode;
}) {
  const { session, isLoadingSession } = useUserSession();
  const user = session?.user || null;

  return (
    <>
      <DesktopView>
        <DesktopHeader
          isLoadingSession={isLoadingSession}
          user={user}
          showTitleSubtext={showTitleSubtext}
          navigationItems={navigationItems}
          hideSessionInfo={hideSessionInfo}
          hideLocalePicker={hideLocalePicker}
          breadcrumbs={breadcrumbs}
        />
      </DesktopView>
      <NonDesktopView>
        <MobileHeader
          isLoadingSession={isLoadingSession}
          user={user}
          navigationItems={navigationItems}
          hideSessionInfo={hideSessionInfo}
          hideLocalePicker={hideLocalePicker}
          breadcrumbs={breadcrumbs}
        />
      </NonDesktopView>
    </>
  );
}

function DesktopHeader({
  isLoadingSession,
  user,
  hideSessionInfo,
  hideLocalePicker,
  navigationItems,
  showTitleSubtext,
  breadcrumbs,
}: {
  isLoadingSession: boolean;
  user: MyUser | null;
  hideSessionInfo?: boolean;
  hideLocalePicker?: boolean;
  navigationItems: NavigationItem[];
  showTitleSubtext?: boolean;
  breadcrumbs?: ReactNode;
}) {
  const [menu, setMenu] = React.useState<HTMLUListElement | null>();
  const [menuValue, setMenuValue] = React.useState<string | null>();
  const [subMenuContent, setSubMenuContent] =
    React.useState<HTMLUListElement | null>();
  const [subMenuOffset, setSubMenuOffset] = React.useState<number | null>();

  // RadixUI does not provide a mechanism to position the submenu in a viewport.
  // This needs to be implemented in user code with additional measurements.
  // See https://github.com/radix-ui/primitives/issues/1462
  //
  // Solution freely adapted from
  // https://github.com/radix-ui/primitives/issues/1462#issuecomment-1155019493
  const onTriggerNodeUpdate = (
    trigger: HTMLButtonElement | null,
    menuItemValue: string,
  ) => {
    if (!menu || !subMenuContent || !trigger) {
      return;
    }
    if (menuValue === menuItemValue) {
      setSubMenuOffset(calculateSubMenuPosition(menu, trigger, subMenuContent));
    }
  };
  const showLoginButton = !isLoadingSession && !user;
  const isAuthenticated = !isLoadingSession && user;
  return (
    <Flex direction={"column"}>
      <Flex px={"7"} py={"5"} align={"center"} justify={"between"}>
        <Flex direction={"column"} align={"start"} justify={"start"}>
          {showTitleSubtext ? <TitleWithSubtext /> : <Title />}
          {breadcrumbs && (
            <Flex py={"3"} px={"0"} asChild>
              {breadcrumbs}
            </Flex>
          )}
        </Flex>
        <NavigationMenu.Root
          className={styles.navigationMenuRoot}
          onValueChange={setMenuValue}
        >
          <NavigationMenu.List ref={setMenu} asChild>
            <Flex
              direction={"row"}
              align={"center"}
              style={{ listStyle: "none" }}
            >
              {navigationItems
                .filter((item) => {
                  return !(
                    item.responsiveState === "mobile" ||
                    (item.authenticatedState === "unauthenticated" &&
                      isAuthenticated) ||
                    (item.authenticatedState === "authenticated" &&
                      !isAuthenticated)
                  );
                })
                .map((item, index) => {
                  return (
                    <NavigationItemLink key={`nav-${index}`}>
                      {item.node}
                    </NavigationItemLink>
                  );
                })}
              {!hideLocalePicker && (
                <Language
                  setList={setSubMenuContent}
                  onTriggerNodeUpdate={onTriggerNodeUpdate}
                />
              )}

              {showLoginButton && !hideSessionInfo && <CombinedAuthCTA />}
              {isLoadingSession && !hideSessionInfo && (
                <NavigationMenu.Item>
                  <LoadingUser />
                </NavigationMenu.Item>
              )}
              {!isLoadingSession && user && !hideSessionInfo && (
                <AccountDetailsUnlessMobile
                  user={user}
                  setList={setSubMenuContent}
                  onTriggerNodeUpdate={onTriggerNodeUpdate}
                />
              )}

              <NavigationMenu.Indicator>
                <div className={styles.arrow} />
              </NavigationMenu.Indicator>
            </Flex>
          </NavigationMenu.List>

          <div className={styles.ViewportPosition}>
            <NavigationMenu.Viewport
              className={styles.navigationMenuViewport}
              style={{
                // Avoid transitioning from initial position when first opening
                // display: !subMenuOffset ? "none" : undefined,
                top: "100%",
                marginLeft: subMenuOffset ? subMenuOffset + "px" : undefined,
              }}
            />
          </div>
        </NavigationMenu.Root>
      </Flex>
    </Flex>
  );
}

type WithSubMenu = {
  onTriggerNodeUpdate?: (node: HTMLButtonElement | null, value: string) => void;
  setList?: React.Ref<HTMLUListElement>;
};

function calculateSubMenuPosition(
  menu: HTMLUListElement,
  trigger: HTMLButtonElement,
  list: HTMLUListElement,
) {
  // We are hardcoding the center offset to prevent jumping after animation as the submenu changes widths. If this looks bad with more dynamic
  // content, we can revisit this
  const centerOffset = 50;
  const leftWhenCentered =
    trigger.offsetLeft + trigger.offsetWidth / 2 - centerOffset;
  const leftMaximum = menu.offsetWidth - list.offsetWidth;
  // align to the left or right, when the centered position overflows
  return Math.round(Math.min(Math.max(leftWhenCentered, 0), leftMaximum));
}

function MobileHeader({
  isLoadingSession,
  user,
  navigationItems,
  hideSessionInfo,
  hideLocalePicker,
  breadcrumbs,
}: {
  isLoadingSession: boolean;
  user: MyUser | null;
  navigationItems: NavigationItem[];
  hideSessionInfo?: boolean;
  hideLocalePicker?: boolean;
  breadcrumbs?: ReactNode;
}) {
  const isAuthenticated = !isLoadingSession && user;
  return (
    <Sheet>
      <Flex direction={"column"} align={"start"} justify={"start"} py={"3"}>
        <Flex
          pl={"5"}
          pr={"3"}
          align={"center"}
          justify={"between"}
          width={"100%"}
        >
          <Title />
          {
            // If these are hidden, we don't need the hamburger menu button
            (navigationItems.length ||
              !hideSessionInfo ||
              !hideLocalePicker) && (
              <SheetTrigger className={styles.hamburgerMenuButton}>
                <HamburgerMenuIcon width={20} height={20} />
              </SheetTrigger>
            )
          }
        </Flex>
        {breadcrumbs && (
          <Flex px={"5"} asChild>
            {breadcrumbs}
          </Flex>
        )}
        <SheetContent style={{ maxWidth: "100%", width: "100%" }} side={"left"}>
          <Flex
            direction={"column"}
            align={"start"}
            justify={"between"}
            p={"4"}
          >
            {isLoadingSession && !hideSessionInfo && <LoadingUser />}
            {!isLoadingSession && user && !hideSessionInfo && (
              <AccountDetailsMobile user={user} />
            )}
            <NavigationMenu.Root className={styles.navigationMenuRoot}>
              <NavigationMenu.List asChild>
                <Flex
                  direction={"column"}
                  align={"start"}
                  style={{ listStyle: "none" }}
                  gap={"2"}
                >
                  {user && <DashboardNavLink />}
                  {navigationItems
                    .filter((item) => {
                      return !(
                        item.responsiveState === "desktop" ||
                        (item.authenticatedState === "unauthenticated" &&
                          isAuthenticated) ||
                        (item.authenticatedState === "authenticated" &&
                          !isAuthenticated)
                      );
                    })
                    .map((item, index) => {
                      return (
                        <NavigationItemLink key={`nav-${index}`}>
                          <DialogPrimitive.Close asChild>
                            {item.node}
                          </DialogPrimitive.Close>
                        </NavigationItemLink>
                      );
                    })}
                  {!hideLocalePicker && <SwitchToOtherLanguage />}
                  {user && !hideSessionInfo && <Logout />}
                  {!isLoadingSession && !user && !hideSessionInfo && (
                    <Box py={"3"}>
                      <CombinedAuthCTA />
                    </Box>
                  )}
                </Flex>
              </NavigationMenu.List>
            </NavigationMenu.Root>
          </Flex>
        </SheetContent>
      </Flex>
    </Sheet>
  );
}

function NavigationItemLink({ children }: { children: React.ReactNode }) {
  return (
    <NavigationMenu.Item className={styles.navigationMenuItem}>
      <TextV2 textStyle={"Body L"} asChild>
        <NavigationMenu.Link className={styles.navigationMenuLink} asChild>
          {children}
        </NavigationMenu.Link>
      </TextV2>
    </NavigationMenu.Item>
  );
}

function AccountDetailsMobile({ user }: { user: MyUser }) {
  return (
    <Flex direction={"row"} align={"center"} justify={"between"} p={"3"}>
      <ProfileAvatar picture={user.picture_url} size={"2"} />
      <NavigationMenu.Item className={`${styles.navigationMenuItem} truncate`}>
        {user.name}
      </NavigationMenu.Item>
    </Flex>
  );
}

function AccountDetailsUnlessMobile({
  user,
  setList,
  onTriggerNodeUpdate: onNodeUpdate,
}: { user: MyUser } & WithSubMenu) {
  return (
    <NavigationMenu.Item value="account" className={styles.navigationMenuItem}>
      <NavigationMenu.Trigger
        ref={(node) => onNodeUpdate && onNodeUpdate(node, "account")}
        className={styles.navigationMenuTrigger}
      >
        <ProfileAvatar
          picture={user.profile_image_sm ?? user.picture_url}
          size={"2"}
        />
        <CaretDownIcon className={styles.caretDown} aria-hidden />
      </NavigationMenu.Trigger>
      <NavigationMenu.Content className={styles.navigationMenuContent}>
        <NavigationMenu.Sub>
          <NavigationMenu.List
            className={styles.navigationMenuSubList}
            ref={setList}
          >
            <NavigationMenu.Item
              className={`${styles.navigatinoMenuIItemDropDown} truncate`}
            >
              <TextV2
                textStyle={"Body L"}
                style={{ color: "var(--colorV2-darkest-green)" }}
              >
                {user.name}
              </TextV2>
            </NavigationMenu.Item>
            <Separator size={"4"} />
            <DashboardNavLink />
            <Logout />
          </NavigationMenu.List>
        </NavigationMenu.Sub>
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  );
}

function DashboardNavLink() {
  const t = useTranslations("Navigation");
  return (
    <NavigationMenu.Item className={styles.navigatinoMenuIItemDropDown}>
      <TextV2 textStyle={"Body L"} asChild>
        <NavigationMenu.Link className={styles.navigationMenuLink} asChild>
          <RadixLink href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}`}>
            {t("dashboard")}
          </RadixLink>
        </NavigationMenu.Link>
      </TextV2>
    </NavigationMenu.Item>
  );
}

function Logout() {
  const t = useTranslations("Navigation");
  return (
    <NavigationMenu.Item className={styles.navigatinoMenuIItemDropDown}>
      <TextV2 textStyle={"Body L"} asChild>
        <NavigationMenu.Link className={styles.navigationMenuLink} asChild>
          {/* eslint-disable-next-line */}
          <a href={`/api/auth/logout`}>{t("logout")}</a>
        </NavigationMenu.Link>
      </TextV2>
    </NavigationMenu.Item>
  );
}

function LoadingUser() {
  return (
    <div className={styles.rippleEffect}>
      <div></div>
      <div></div>
    </div>
  );
}

function Title() {
  const t = useTranslations("Navigation");
  return (
    <Link href="/" className={styles.navigationmenuLogoLink}>
      <Image
        src={logoText}
        width={172}
        height={23}
        alt={t("title")}
        priority={true}
        unoptimized={true}
      />
    </Link>
  );
}

function TitleWithSubtext() {
  const t = useTranslations("Navigation");
  return (
    <Link href="/" className={styles.navigationmenuLogoLink}>
      <Flex direction={"column"} align={"start"}>
        <Image
          src={logoText}
          alt={t("title")}
          priority={true}
          style={{ width: "182px" }}
        />
      </Flex>
    </Link>
  );
}

function CombinedAuthCTA() {
  const t = useTranslations("Navigation");
  return (
    <NavigationMenu.Item>
      <NavigationMenu.Link className={styles.signupCTALink} asChild>
        {/* eslint-disable-next-line */}
        <ButtonV2 color={"mint"} size={"3"} mx={"3"} asChild>
          <a href="/api/auth/login">{t("login_signup")}</a>
        </ButtonV2>
      </NavigationMenu.Link>
    </NavigationMenu.Item>
  );
}

function Language({ onTriggerNodeUpdate: onNodeUpdate, setList }: WithSubMenu) {
  return (
    <NavigationMenu.Item
      value="localeSwitch"
      className={styles.navigationMenuGlobeItem}
    >
      <NavigationMenu.Trigger
        className={styles.navigationMenuTrigger}
        ref={(node) => onNodeUpdate && onNodeUpdate(node, "localeSwitch")}
      >
        <GlobeIcon className={styles.globeIcon} aria-hidden />
        <CaretDownIcon className={styles.caretDown} aria-hidden />
      </NavigationMenu.Trigger>
      <NavigationMenu.Content className={styles.navigationMenuContent}>
        <NavigationMenu.Sub>
          <NavigationMenu.List
            className={styles.navigationMenuSubList}
            ref={setList}
          >
            <LanguageItem itemLocale="en" />
            <LanguageItem itemLocale="de" />
          </NavigationMenu.List>
        </NavigationMenu.Sub>
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  );
}

function LanguageItem({ itemLocale }: { itemLocale: "en" | "de" }) {
  const t = useTranslations("Navigation");
  const locale = useLocale();
  const router = useRouter();
  const pathname = usePathname();
  const params = useParams();
  const searchParams = useSearchParams();
  const allParams = searchParams?.toString();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fullPath = `${pathname}${allParams ? `?${allParams}` : ""}` as any;
  const isCurrentLocale = locale === itemLocale;
  const locale_key = itemLocale === "en" ? "english" : "german";
  if (isCurrentLocale) {
    return (
      <NavigationMenu.Item
        className={styles.navigationMenuSubMenuItem}
        style={{ paddingLeft: "0", paddingRight: "0", textAlign: "center" }}
      >
        <Flex
          align={"center"}
          px={"2"}
          justify={"center"}
          style={{ cursor: "default", color: "var(--colorV2-dark-green)" }}
        >
          <CheckIcon width={20} height={20} />
          <TextV2 textStyle={"Body L"}>{t(locale_key)}</TextV2>
          <div style={{ width: "20px" }} />
        </Flex>
      </NavigationMenu.Item>
    );
  } else {
    return (
      <NavigationMenu.Item
        className={styles.navigationMenuSubMenuItem}
        style={{ paddingLeft: "20px", paddingRight: "20px" }}
      >
        <TextV2 textStyle={"Body L"}>
          <NavigationMenu.Link className={styles.navigationMenuLink} asChild>
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore : https://github.com/amannn/next-intl/issues/581
                router.replace(
                  { pathname: fullPath, params },
                  { locale: itemLocale },
                );
              }}
            >
              <Flex align={"center"} px={"2"} justify={"center"}>
                {t(locale_key)}
              </Flex>
            </a>
          </NavigationMenu.Link>
        </TextV2>
      </NavigationMenu.Item>
    );
  }
}

function SwitchToOtherLanguage() {
  const locale = useLocale();
  const router = useRouter();
  const pathname = usePathname();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params = useParams() as any;
  const t = useTranslations("Navigation");
  return (
    <NavigationItemLink>
      <a
        href="#"
        onClick={() =>
          router.replace(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore : https://github.com/amannn/next-intl/issues/581
            { pathname, params },
            { locale: locale == "en" ? "de" : "en" },
          )
        }
      >
        <Flex direction={"row"} align={"center"} gap={"2"}>
          <GlobeIcon className={styles.globeIcon} aria-hidden />{" "}
          {t(locale == "en" ? "german" : "english")}
        </Flex>
      </a>
    </NavigationItemLink>
  );
}

export { Header };

export type { NavigationItem };
