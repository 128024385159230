import React, { ForwardedRef, MouseEvent } from "react";
import { navigateToExternalUrl } from "@/utils/NavigationUtils";
import MonitoringService from "@lib/MonitoringService";

interface ExternalLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  url: string;
  children: string | React.ReactNode;
}

const ExternalLink = React.forwardRef<HTMLAnchorElement, ExternalLinkProps>(
  // we want to copy the styling applied to this component to the anchor tag (but not the url)
  ({ url, children, ...rest }, ref: ForwardedRef<HTMLAnchorElement>) => {
    const handleClick = async (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      try {
        await navigateToExternalUrl(url);
      } catch (error) {
        MonitoringService.captureException(error);
      }
    };

    return (
      <a {...rest} ref={ref} href="#" onClick={handleClick}>
        {children}
      </a>
    );
  },
);

ExternalLink.displayName = "ExternalLink";

export default ExternalLink;
