import { Avatar as RadixAvatar } from "@radix-ui/themes";
import React from "react";

type AvatarProps = React.ComponentProps<typeof RadixAvatar>;

// Exclude 'fallback' and 'src' from AvatarProps
type ProfileAvatarProps = Omit<AvatarProps, "fallback" | "src"> & {
  picture?: string;
  radius?: AvatarProps["radius"];
  size?: AvatarProps["size"];
};

function ProfileAvatar({
  picture,
  radius = "full",
  size = "2",
  ...props
}: ProfileAvatarProps) {
  return (
    <RadixAvatar
      src={picture}
      radius={radius}
      size={size}
      fallback={
        // eslint-disable-next-line @next/next/no-img-element
        <img
          src={"/images/profile_picture.svg"}
          width={"100%"}
          height={"100%"}
          alt={"Avatar"}
        />
      }
      {...props}
    />
  );
}
export default ProfileAvatar;
